<template>
  <sidenav
      v-if="showSidenav"
      :custom_class="color"
      :class="[isRTL ? 'fixed-end' : 'fixed-start']"
  />
  <main class="main-content position-relative max-height-vh-100 h-100 overflow-x-hidden">
    <!-- nav -->
    <navbar
        v-if="showNavbar"
        :class="[isNavFixed ? navbarFixed : '', isAbsolute ? absolute : '']"
        :color="isAbsolute ? 'text-white opacity-8' : ''"
        :min-nav="navbarMinimize"
    />
    <router-view />
  </main>
</template>

<script>
import { mapMutations, mapState } from "vuex";

import Sidenav from './components/Sidenav.vue'
import Navbar from './components/Navbar.vue'

export default {
  name: "MainLayout",
  components: {
    Sidenav,
    Navbar
  },
  computed: {
    ...mapState([
      "isRTL",
      "color",
      "isAbsolute",
      "isNavFixed",
      "navbarFixed",
      "absolute",
      "showSidenav",
      "showNavbar",
      "showFooter",
      "showConfig",
      "hideConfigButton",
    ]),
  },
  beforeMount() {
    const sidenav = document.getElementsByClassName("g-sidenav-show")[0];

    if (window.innerWidth > 1200) {
      sidenav.classList.add("g-sidenav-pinned");
    }
  },
  methods: {
    ...mapMutations(["toggleConfigurator", "navbarMinimize"]),
  },
}
</script>

<style scoped>

</style>
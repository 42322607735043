<template>
  <aside
      id="sidenav-main"
      class="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 ms-3 fixed-start"
      :class="`${sidebarType}`"
  >
    <div class="sidenav-header">
      <i
          id="iconSidenav"
          class="top-0 p-3 cursor-pointer fas fa-times text-secondary opacity-5 position-absolute end-0 d-none d-xl-none"
          aria-hidden="true"
      ></i>
      <router-link class="m-0 navbar-brand" to="/">
        <span class="ms-1 font-weight-bold text-white">宠物大投票管理</span>
      </router-link>
    </div>
    <hr class="horizontal light mt-0 mb-2" />
    <div
        id="sidenav-collapse-main"
        class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100">
      <ul class="navbar-nav">
        <li class="nav-item active">
          <sidenav-item
              :to="{ name: 'kanban' }"
              mini-icon="K"
              text="实时数据"
          />
          <sidenav-item
              :to="{ name: 'voteList' }"
              mini-icon="V"
              text="数据列表"
          />
        </li>
      </ul>
    </div>
  </aside>
</template>

<script>
import { mapState } from "vuex";
import SidenavItem from "@/layout/components/SidenavItem.vue";

export default {
  name: "Index",
  components: {
    SidenavItem
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapState(["sidebarType", "isDarkMode"]),
  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    }
  }
};
</script>

<style scoped>

</style>
import { createRouter, createWebHistory } from "vue-router";

import cookies from '@/plugin/cookies';

import routes from './routes';

const router = createRouter({
  history: createWebHistory('/'),
  mode: 'history',
  routes,
  linkActiveClass: "active",
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(r => r.meta.auth)) {
    const token = cookies.get('token');
    console.log('TOKEN:', token);
    console.log(to.path);
    if (token && token !== undefined) {
      const menuUrls = cookies.get('urls');
      console.log(menuUrls.includes(to.path));
      if (to.name === '404' || (menuUrls && menuUrls.includes(to.path))) {
        next();
      } else {
        next({
          name: '404'
        });
      }
    } else {
      next({
        name: 'login'
      });
    }
  } else {
    next();
  }
});

export default router;

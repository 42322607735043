<template>
  <div class="app-container app-theme-white">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'app',
  components: {
  },
  methods:{

  }
}
</script>
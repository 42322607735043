export default [{
  path: '/login',
  name: 'login',
  meta: {
    auth: false,
    layout: 'empty'
  },
  component: () => import('@/views/Login.vue')
}, {
  path: '/dashboard/kanban',
  name: 'kanban',
  meta: {
    auth: true,
    layout: 'main'
  },
  component: () => import('@/views/KanBan.vue')
}, {
  path: '/dashboard/voteList',
  name: 'voteList',
  meta: {
    auth: true,
    layout: 'main'
  },
  component: () => import('@/views/VoteList.vue')
}, {
  path: '/',
  redirect: '/dashboard/kanban'
}, {
  path: '/404',
  name: '404',
  meta: {
    layout: 'empty'
  },
  component: () => import('@/views/404.vue')
}, {
  path: '/:pathMatch(.*)',
  redirect: '/404'
}]
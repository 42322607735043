<template>
  <component :is="layout"></component>
</template>

<script>

const empty_layout = "empty";

export default {
  name: "App",
  computed: {
    layout() {
      return (this.$route.meta.layout || empty_layout) + '-layout';
    }
  }
};
</script>

<style>
.dataTable-pagination-list .active a {
  background-image: linear-gradient(
      195deg,
      #66bb6a 0%,
      #43a047 100%
  ) !important;
}
</style>

import Cookie from 'js-cookie';

const cookies = {};

cookies.set = (name = 'default', value = '', cookieSetting = {}) => {
  const currentCookieSetting = {
    expires: 1
  };
  Object.assign(currentCookieSetting, cookieSetting);
  Cookie.set(`pv_web_admin-${ name }`, value, currentCookieSetting);
}

cookies.get = (name = 'default') => {
  return Cookie.get(`pv_web_admin-${ name }`);
}

cookies.getAll = () => {
  return Cookie.get();
}

cookies.remove = (name = 'default') => {
  return Cookie.remove(`pv_web_admin-${ name }`);
}

export default cookies;